//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import axios from "axios";
import api from "../api.js";
export default {
  data: () => ({
    form: false,
    itemOne: {
      workPerformed: "",
      state: "",
    },
    orderStateColor: "",
  }),
  created() {},
  mounted() {
   // this.orderStateColor=this.$route.query.color;
   this.orderStateColor=this.$route.params.color
    this.getItem();
  },
  methods: {
    goBack() {
      this.$router.push("/workcalendaredit");
    },
    goMenu() {
      this.$router.push("/mainmenu");
    },
    omplirValors(rdata) {
      this.itemOne = rdata;
    },
    getItem() {
      axios
        .post(api.URL() + "/api/v1/gordresfabricacio00_getbyid", {
          token: sessionStorage.getItem("Token"),
          docCompanyId: parseInt(sessionStorage.getItem("sessDocCompanyId")),
          docYear: parseInt(sessionStorage.getItem("sessDocYear")),
          docSerialId: parseInt(sessionStorage.getItem("sessDocSerialId")),
          docId: parseInt(sessionStorage.getItem("sessDocId")),
        })
        .then((response) => {
          if (response.error == null) {
            this.omplirValors(response.data);
          } else {
            this.msgAlert(response.error, true);
          }
        })
        .catch(function (error) {
          this.msgAlert(error.response, true);
        });
    },
    updateItem(message) {
      axios
        .post(api.URL() + "/api/v1/gordresfabricacio00_updateworkperformed", {
          token: sessionStorage.getItem("Token"),
          docCompanyId: parseInt(sessionStorage.getItem("sessDocCompanyId")),
          docYear: parseInt(sessionStorage.getItem("sessDocYear")),
          docSerialId: parseInt(sessionStorage.getItem("sessDocSerialId")),
          docId: parseInt(sessionStorage.getItem("sessDocId")),
          userId: parseInt(sessionStorage.getItem("UserId")),
          workPerformed: message,
        })
        .then((response) => {
          if (response.error != null) {
            this.msgAlert(response.error, true);
          }
          this.goBack();
        })
        .catch(function (error) {
          this.msgAlert(error.response, true);
        });
    },
    msgAlert(missatge, close) {
      var onClose = () => {
        if (close && close == true) {
          this.$router.push("/");
        }
      };
      this.$alert.show({ message: missatge, onClose: onClose });
    },
  },
};
