import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
import { VToolbar } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"text-center"},[_c(VCard,[_c(VToolbar,{attrs:{"color":_vm.orderStateColor,"cards":"","dark":"","flat":""}},[_c(VBtn,{attrs:{"color":_vm.orderStateColor},on:{"click":function($event){return _vm.goBack()}}},[_c(VIcon,[_vm._v("mdi-arrow-left")])],1),_c(VSpacer),_vm._v(" Treballs extres realitzats "),_c(VSpacer),_c(VBtn,{attrs:{"color":_vm.orderStateColor},on:{"click":function($event){return _vm.goMenu()}}},[_c(VIcon,[_vm._v("mdi-exit-to-app")])],1)],1),_c('br'),_c('br'),_c(VForm,{ref:"form",model:{value:(_vm.form),callback:function ($$v) {_vm.form=$$v},expression:"form"}},[_c(VTextarea,{attrs:{"counter":"250","filled":"","label":"Treballs realitzats","rows":"4"},model:{value:(_vm.itemOne.workPerformed),callback:function ($$v) {_vm.$set(_vm.itemOne, "workPerformed", $$v)},expression:"itemOne.workPerformed"}})],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"disabled":!_vm.form,"color":_vm.orderStateColor},on:{"click":function($event){return _vm.updateItem(_vm.itemOne.workPerformed)}}},[_c(VIcon,[_vm._v("mdi-content-save")]),_vm._v(" Guardar ")],1),_c(VBtn,{attrs:{"color":_vm.orderStateColor},on:{"click":function($event){return _vm.goBack()}}},[_c(VIcon,[_vm._v("mdi-content-save-off")]),_vm._v(" Cancel.lar ")],1),_c(VSpacer)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }